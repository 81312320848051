<!-- eslint-disable vue/valid-v-model -->
<template>
  <fragment v-if="!isLoading">
    <tr>
      <td
        style="margin-left: 25px;
        margin-top: 0px;
        min-width: 650px !important;
        text-align: left;
        vertical-align: top;"
      >
        <img
          style="max-width: 250px;max-height: 250px;"
          :src="`${url_public}config/logopdf.png`"
        />
      </td>
      <td
        width="50%"
        style="min-width: 350px !important;
              text-align: right;"
      >
        <v-simple-table>
          <template v-slot:default>
            <tbody style="min-width: 350px !important;">
              <tr style="text-align: left;">
                <td colspan="2">
                  <b>Voucher</b> {{ item.number_vouchers[0].voucher }}
                </td>
              </tr>
              <tr>
                <td style="text-align: right;padding-left: 10px;">
                  <b>{{ $t('lbl.noConfirm') }}:</b>
                </td>
                <td style="text-align: left;">
                  <fragment v-if="item.number_confirmed">
                    {{ item.number_confirmed }}
                  </fragment>
                </td>
              </tr>
              <tr v-if="proveedor">
                <td style="text-align: right;padding-left: 10px;">
                  <fragment>
                    <b>{{ $t('lbl.operaLocal') }}:</b>
                  </fragment>
                </td>
                <td style="text-align: left;">
                  <fragment> {{ proveedor }} </fragment>
                </td>
              </tr>
              <tr v-if="rentadora">
                <td style="text-align: right;padding-left: 10px;">
                  <fragment>
                    <b>{{ $t('lbl.rental') }}:</b>
                  </fragment>
                </td>
                <td style="text-align: left;">
                  <fragment>
                    {{ rentadora }}
                  </fragment>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </tr>
    <tr v-if="item.entity === 'cars'">
      <td colspan="2">
        <v-simple-table style="margin-top: 125px;margin-left: 50px;">
          <template v-slot:default>
            <!--border: 1px solid;-->
            <tbody style="min-width: 350px !important;">
              <tr>
                <td style="text-align: right;">
                  <b>{{ $t('lbl.conductorPrincipal') }}:</b>
                </td>
                <td style="text-align: left;">
                  {{ item.data_client.nameConductorPrincipal }}
                  {{ item.data_client.nameSecoundConductorPrincipal }}
                  {{ item.data_client.apellidosConductorPrincipal }}
                  <span
                    v-if="item.data_client.noDocumentConductorPrincipal"
                  >({{ item.data_client.noDocumentConductorPrincipal }})</span>
                </td>
              </tr>
              <tr v-if="item.data_client.conductorAditional">
                <td style="text-align: right;">
                  <b>{{ $t('lbl.conductorAditional') }}:</b>
                </td>
                <td style="text-align: left;">
                  {{ item.data_client.nameConductorAditional }}
                  {{ item.data_client.nameSecoundConductorAditional }}
                  {{ item.data_client.apellidosConductorAditional }}
                  <span
                    v-if="item.data_client.noDocumentConductorAditional"
                  >({{ item.data_client.noDocumentConductorAditional }})</span>
                </td>
              </tr>
              <tr>
                <td
                  style="text-align: left;"
                  colspan="2"
                >
                  <p style="margin-top: 15px">
                    <b>{{ $t('lbl.car') }}:</b> {{ item.data_service.items.car.name }}
                    <span> o similar </span>
                    <span
                      v-if="item.data_service.items.category_id"
                    >({{ item.data_service.items.category_id.name }})</span>
                  </p>
                </td>
              </tr>
              <!--RECOGIDA-->
              <tr>
                <td style="text-align: right;">
                  <b>{{ $t('lbl.recogida') }}:</b>
                </td>
                <td style="text-align: left;">
                  {{ item.data_service.recogida.name }}
                </td>
              </tr>
              <tr>
                <td style="text-align: right;"></td>
                <td style="text-align: left;">
                  <span>{{ $moment(item.data_service.dateRecogida).format('D-MMM-YYYY, H:mm') }}</span>
                </td>
              </tr>
              <!--ENTREGA-->
              <tr>
                <td style="text-align: right;">
                  <br />
                  <b style="margin-top: 50px;">{{ $t('lbl.entrega') }}:</b>
                </td>
                <td style="text-align: left;">
                  <br />
                  {{ item.data_service.entrega.name }}
                </td>
              </tr>
              <tr>
                <td style="text-align: right;"></td>
                <td style="text-align: left;">
                  <span>{{ $moment(item.data_service.dateEntrega).format('D-MMM-YYYY, H:mm') }}</span>
                </td>
              </tr>
              <!--DIAS-->
              <tr>
                <td style="text-align: right;">
                  <br />
                  <b style="margin-top: 50px;">{{ $t('lbl.daysRent') }}:</b>
                </td>
                <td style="text-align: left;">
                  <br />
                  {{ item.data_service.items.diasReservar }}
                </td>
              </tr>
              <!--SUPLEMENTOS-->
              <tr v-if="item.data_service.items.suplementos.length > 0">
                <td style="text-align: right;">
                  <br />
                  <b style="margin-top: 50px;">{{ $t('lbl.incluye') }}:</b>
                </td>
                <td style="text-align: left;"></td>
              </tr>
              <tr
                v-for="(suplement, indS) in item.data_service.items.suplementos"
                :key="indS"
              >
                <td style="text-align: right;"></td>
                <td style="text-align: left;">
                  <span>
                    {{ suplement.suplemento.name
                    }}<span v-if="indS < item.data_service.items.suplementos.length - 1">
                      <br />
                    </span>
                  </span>
                </td>
              </tr>
              <!--CONDICIONES-->
              <fragment v-if="conditions.length > 0">
                <tr
                  v-for="(condition, indC) in conditions"
                  :key="indC"
                >
                  <td
                    style="text-align: left;"
                    colspan="2"
                  >
                    <br v-if="indC === 0" />
                    <!--<b>{{ condition.name }}</b>
                    <ol>
                      <li
                        v-for="(cod, indCod) in condition.conditions"
                        :key="indCod"
                      >
                        {{ cod.name }}<br />
                        <span v-html="cod.description"></span>
                      </li>
                    </ol>-->
                    <b>{{ condition.name }}</b>
                    <span
                      v-for="(cod, indCod) in condition.conditions"
                      :key="indCod"
                    >
                      <p
                        style="font-size: 9px;"
                        v-html="cod.description"
                      ></p>
                    </span>
                  </td>
                </tr>
              </fragment>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </tr>

    <tr>
      <td
        colspan="2"
        style="text-align: center;"
      >
        <p style="margin-top: 125px;margin-left: 100px;">
          Ciudad de Guatemala, {{ $moment(item.date_compra).format('D-MMM-YYYY') }}
        </p>
      </td>
    </tr>

    <tr>
      <td colspan="2">
        <v-simple-table style="margin-top: 25px;margin-left: 50px;">
          <template v-slot:default>
            <tbody style="min-width: 350px !important;">
              <tr>
                <td style="text-align: left;">
                  <p>
                    Whatsapp +502 4066 6270 <br />
                    6A Ave. 6-91 Oficina 01, 4to. Nivel, Zona 9, <br />
                    Ciudad de Guatemala, Guatemala C.A., 01009 <br />
                    wamasoltours.com
                  </p>
                </td>
                <td style="text-align: right;">
                  <img
                    style="max-width: 540;
                            max-height: 350px;
                            margin-bottom: 25px;
                            margin-left: 50px;
                          "
                    max-height="150"
                    max-width="540"
                    :src="`${url_public}voucher/cunnow.png`"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </tr>

    <tr>
      <td
        colspan="2"
        style="text-align: left;"
      >
        <v-simple-table style="margin-top: 50px;margin-left: 50px;">
          <template v-slot:default>
            <tbody style="min-width: 350px !important;font-size: 12px;">
              <tr>
                <td style="text-align: left;">
                  <b>Nota:</b>
                </td>
                <td style="text-align: left;">
                  <span>El número de confirmación del voucher es emitido por el proveedor local.</span>
                </td>
              </tr>
              <tr>
                <td style="text-align: left;"></td>
                <td style="text-align: left;">
                  <span>Al momento de su presentación será verificada su reserva.</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </tr>
  </fragment>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
  },
  data() {
    return {
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      proveedor: null,
      rentadora: null,
      idConditionsContrate: [],
      conditions: [],
      condicionesGenerales: [],
      categoriesCondiciones: [],
    }
  },
  created() {
    this.getContrate()
  },
  methods: {
    getCategCondiciones() {
      const json = {
        for_car: 1,
        per_page: 10000,
      }
      this.axios
        .post('generals/categories', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categoriesCondiciones = res.data.data
          }
        })
        .finally(() => {
          this.getCondiciones()
        })
    },
    getCondiciones() {
      const json = {
        for_car: 1,
        per_page: 10000,
      }
      this.axios
        .post('generals/conditions', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
        .finally(() => {
          this.checkConditions()
        })
    },
    getContrate() {
      if (this.item.entity === 'cars') {
        this.axios
          .get(`contrate_cars/${this.item.data_service.items.contrate_id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.proveedor = res.data.data.data.proveedor.name_comercial
            this.rentadora = res.data.data.data.rentadora.name
            if (res.data.data.data.conditions) {
              this.idConditionsContrate = res.data.data.data.conditions
            }
          })
          .finally(() => {
            this.getCategCondiciones()
          })
      } else {
        this.isLoading = false
      }
    },
    checkConditions() {
      // console.log(this.idConditionsContrate)
      // console.log(this.condicionesGenerales)

      const arr = []
      this.condicionesGenerales.forEach(element => {
        if (this.idConditionsContrate.includes(element.id)) {
          arr.push(element)
        }
      })

      this.categoriesCondiciones.forEach(element => {
        let tieneConditions = false

        const cond = []
        arr.forEach(elementArr => {
          if (elementArr.category_id === element.id) {
            tieneConditions = true

            cond.push({
              name: elementArr.name,
              description: elementArr.description,
            })
          }
        })

        if (tieneConditions) {
          this.conditions.push({
            name: element.name,
            conditions: cond,
          })
        }
      })

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
  },
}
</script>
